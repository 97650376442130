
/**
 * 客服信息
 */
export default {
  customerAdd: '/customer/add', //  添加
  customerDelete: '/customer/delete', //  删除
  customerList: '/customer/list', //  列表
  customerPut: '/customer/put', //  修改
  customerQuery: '/customer/query', //  详情

  // enterIm: '/tencentyun/enterIm', // 进入im
  enterIm: '/tencentIm/login', // 登录腾讯im
  getPlatformIm: '/tencentIm/getPlatformIm', // 获取接收方（总后台）accountId

};
