/**   
 * 投标管理api
 */
export default {
  /**
   *  招标列表
   */
// 招标信息列表
sendTenderList: "/tendersInfo/supplierPageList",
// 标期下拉列表
sendBidPeriodPage: "/tendersInfo/bidPeriodPage",
  /**
   * 评标审议
   */
// 评标审议列表
sendSupplierTenders:`/tendersInfo/supplierTendersTabulation`,
// 评标审议列表查看是否中标
sendSupplierTendersView:`/tendersInfo/supplierTendersView`,
// 我的投标列表
sendMyTenders:`/tendersInfo/myTenders`,
  /**
   * 我的中标
   */
  // 我的中标列表
sendMyWinTenders:`/tendersInfo/myWinTenders`,
// 缴费详情
sendMyWinTendersProduct:`/tendersInfo/myWinTendersProduct`,
  /**
   * 应标资格
   */
  //应标管理
sendBiddingEligible:`/biddingEligible/biddingEligibleList`,
// 验证是否有资格
sendCheckEligible:`/biddingEligible/checkEligible`,
// 申请资格
sendAddEligible:`/biddingEligible/add`,
// 投标
sendAddBidding:`/biddingProduct/add`,
// 修改投标
sendUpdateBidding:`/biddingProduct/update`,
// 取消投标
sendDelBidding:`/biddingProduct/delete`,
// 应标管理审核通过查看
sendBiddingDetail:`/biddingEligible/biddingEligibleDetail`,
// 应标管理应标资格费用平台
sendSelectTendersEligible:`/biddingEligible/selectTendersEligible`,
// 应标管理应标资格费用独立标
sendTendersNoticeCheck:`/tendersInfo/tendersNoticeCheck`,

};