/**   
 * 调价管理api
 */

export default {
  /**
   * 调价列表
   */
  // 列表
  getProductAdjustPriceList: "/productAdjustPrice/list",
  // 商品查询 / 商品编号查询
  queryProductList: "/productAdjustPrice/queryProduct",
//申请调价商品列表
biddingSuppliedGoodsList:'/biddingProduct/getSuppliedGoodsList',
  
  // 申请调价
  addProductAdjustPrice: "/productAdjustPrice/add",
  // 批量导入申请
  importAdjustPrice: "/productAdjustPrice/importAdjustPrice",
  /**
   * 我的申请
   */
  // 列表
  getMySubscribeList: "/productAdjustPrice/mySubscribeList",
  // 我的申请详情/调价详情
  queryMySubscribeDetail: "/productAdjustPrice/queryDetail",
  // 我的申请 - 撤销申请
  deleteMySubscribe: "/productAdjustPrice/delete",
};