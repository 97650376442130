// 经营信息
export default [
  {
    path: "/business",
    meta: { title: "经营信息" },
    component: () => import("../views/business/index.vue"),
    children: [
      {
        path: "/business/basicInfo",
        meta: { title: "基本信息", isMenu: true },
        component: () => import("../views/business/basicInfo.vue"),
      },
      {
        path: "/business/shipper",
        meta: { title: "送货人信息", isMenu: true },
        component: () => import("../views/business/shipper.vue"),
      },
      {
        path: "/business/vehicle",
        meta: { title: "车辆管理", isMenu: true },
        component: () => import("../views/business/vehicle.vue"),
      },
      {
        path: "/business/distribution",
        meta: { title: "配送区域", isMenu: true },
        component: () => import("../views/business/distribution.vue"),
      },
      {
        path: "/business/detail/shopList",
        meta: { title: "查看商超" },
        component: () => import("../views/business/detail/shopList.vue"),
      },
    ],
  },
]
