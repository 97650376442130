// 投标管理
export default [
  {
    path: '/bidManagement',
    meta: { title: '投标管理' },
    component: () => import('../views/bidManagement/index.vue'),
    children: [
      {
        path: '/bidManagement/bidMes',
        meta: { title: '招标列表', isMenu: true },
        component: () => import('../views/bidManagement/bidMes.vue'),
      },
      {
        path: '/bidManagement/detail/bidList',
        name: 'bidList',
        meta: { title: '评标审议' },
        component: () => import('../views/bidManagement/detail/bidList.vue'),
      },
      {
        path: '/bidManagement/winBid',
        name: 'winBid',
        meta: { title: '我的中标', isMenu: true },
        component: () => import('../views/bidManagement/winBid.vue'),
      },
      {
        path: '/bidManagement/myBid',
        name: 'myBid',
        meta: { title: '我的投标', isMenu: true },
        component: () => import('../views/bidManagement/myBid.vue'),
      },
      {
        path: '/bidManagement/resBidMan',
        meta: { title: '应标管理', isMenu: true },
        component: () => import('../views/bidManagement/resBidMan.vue'),
      },
      {
        path: '/bidManagement/detail/winBidDet',
        name: 'winBidDet',
        meta: { title: '保证金详情' },
        component: () => import('../views/bidManagement/detail/winBidDet.vue'),
      },
      {
        path: '/bidManagement/detail/bidEligibility',
        name: 'bidEligibility',
        meta: { title: '申请应标资格' },
        component: () => import('../views/bidManagement/detail/bidEligibility.vue'),
      },
      {
        path: '/bidManagement/detail/paymentPage',
        name: 'paymentPage',
        meta: { title: '缴费页面' },
        component: () => import('../views/bidManagement/detail/paymentPage.vue'),
      },
      {
        path: '/bidManagement/detail/resBidManDet',
        name: 'detailResBidManDet',
        meta: { title: '应标资格' },
        component: () => import('../views/bidManagement/detail/resBidManDet.vue'),
      },
      // {
      //   path: '/bidManagement/detail/paymentDet',
      //   meta: { title: '缴费详情'},
      //   component: () => import('../views/bidManagement/detail/paymentDet.vue'),
      // },

    ],
  },
]