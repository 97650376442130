/**
 * 自定义指令-按钮权限
 */
export default {
  name: 'btn-auth', // 自定义指令名称
  bind: function (el, binding, vnode) {
    const permissionKey = binding.menuStr; // 权限标识

    // 从 Vue 实例的 menuList 中获取用户按钮权限数据
    const data = vnode.context.$store.menuList || [];

    // 递归筛选出所有类型为按钮的权限数据
    function findButtons (permissions) {
      return permissions.reduce((acc, item) => {
        if (item.menuType === 1) {
          acc.push(item.permissionKey || item.menuId); // 每个按钮权限项的唯一的标识符
        }
        if (item.children.length > 0) {
          acc = acc.concat(findButtons(item.children));
        }
        return acc;
      }, []);
    }

    const buttonPermissions = findButtons(data);

    // 判断用户是否拥有指定的权限
    const hasPermission = buttonPermissions.includes(permissionKey);

    if (!hasPermission) {
      el.style.display = 'none'; // 若无权限，则隐藏按钮
    }
  },
};