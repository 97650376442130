import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex)

export default new Vuex.Store({
  // 默认存储到本地local Storage里
  plugins: [createPersistedState()],
  state: {
    token: '',
    isDetail: null, // 是否详情，是详情页则缓存上一级为菜单的path
    menuList: null, // 菜单列表
    sideMenuActive: null, // 子菜单
    userInfo: null, // 用户信息
    isLoggedIn: false, // 用户是否已登录
    thirdToken: null, // 第三方聚合支付永久token
    payQuery: null, // 第三方聚合支付获取二维码的参数
    isLoginIM: false, // 是否已登录腾讯IM
  },
  mutations: {
    setToken (state, token) {
      state.token = token
    },
    setSideMenuActive (state, sideMenuActive) {
      state.sideMenuActive = sideMenuActive
    },
    setIsDetail (state, isDetail) {
      state.isDetail = isDetail
    },
    setMenuList (state, menuList) {
      state.menuList = menuList
    },
    setUserInfo (state, userInfo) {
      state.userInfo = userInfo
    },
    // 用于修改用户信息后的更新
    setUserInfoUpdate (state, payload) {
      if (typeof payload === 'object' && payload !== null) {
        const hasOwn = Object.prototype.hasOwnProperty; // 获取hasOwnProperty的原始引用
        for (const key in payload) {
          if (hasOwn.call(payload, key)) {
            state.userInfo[key] = payload[key];
          }
        }
      }
    },
    setIsLoggedIn (state, isLoggedIn) {
      state.isLoggedIn = isLoggedIn
    },
    setThirdToken (state, thirdToken) {
      state.thirdToken = thirdToken
    },
    setPayQuery (state, payQuery) {
      state.payQuery = payQuery
    },
    setIsLoggedIM (state, isLoginIM) {
      state.isLoginIM = isLoginIM
    },
    logout (state) {
      state.token = ''
      state.isDetail = null
      state.menuList = null
      state.sideMenuActive = null
      state.userInfo = null
      state.thirdToken = null
      state.payQuery = null
      state.isLoggedIn = false
      state.isLoginIM = false
    },
  },
  actions: {
    // 登出
    async logout ({ commit }) {
      await commit('logout');
    },
    updateUserInfos ({ commit }, updates) {
      commit('setUserInfoUpdate', updates);
    },
  },
  modules: {
  }
})
