import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import system from './system'
import products from './products'
// import logistics from './logisticsManagement'
import orderTaking from './orderTakingManagement'
import refundsExchanges from './refundsExchanges'
import bidManagement from './bidManagement'
import onlineTrading from './onlineTrading'
import settlement from './settlement'
import technicalService from './technicalService'
import conManagement from './conManagement.js'
import priceAdjus from './priceAdjus'
import stock from './stock'
import business from './business'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    meta: { title: '校园云采' },
    component: () => import('../views/Main.vue'),
    children: [
      ...system,
      ...products,
      ...settlement,
      // ...logistics,
      ...orderTaking,
      ...refundsExchanges,
      ...bidManagement,
      ...onlineTrading,
      ...technicalService,
      ...conManagement,
      ...priceAdjus,
      ...stock,
      ...business,
    ],
  },
  {
    path: '/login',
    name: 'login',
    meta: { title: '供应商管理后台-登录' },
    component: () => import('../views/Login.vue')
  },
  {
    path: '/register',
    name: 'register',
    meta: { title: '注册' },
    component: () => import('../views/register.vue')
  },
  {
    path: '/forgotPassword',
    name: 'forgotPassword',
    meta: { title: '忘记密码' },
    component: () => import('../views/forgotPassword.vue')
  },
  {
    path: '/404',
    name: '404',
    meta: { title: '服务器拒绝访问或页面不存在' },
    component: () => import('../views/404.vue')
  },
]

const router = new VueRouter({
  routes
})

const originaPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push (location) {
  return originaPush.call(this, location).catch(err => err)
}


router.beforeEach((to, form, next) => {
  const token = store.state.token

  if (to.path == '/register' || to.path == '/forgotPassword') {
    next()
  } else if (!token && to.path !== '/login') {
    store.dispatch('logout')
    next('/login');
  } else if (token && to.path == '/login') {
    store.dispatch('logout')
    localStorage.clear()
    next()
  } else {
    next()
  }
})

router.afterEach((to) => {
  if (to.meta.title) document.title = to.meta.title
})
export default router
