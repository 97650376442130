/**
 * 系统管理api
 */
export default {
  /**
   * 经营信息
   */
  getMySupplier: "/supplierInfo/getMySupplier", // 基本信息
  updateMySupplier: "/supplierInfo/updateMySupplier", // 基本信息修改

  supplierDeliveryCarAdd: "/supplierDeliveryCar/add", //  车辆信息新增
  supplierDeliveryCarEdit: "/supplierDeliveryCar/audit", //  车辆信息修改
  supplierDeliveryCarDelete: "/supplierDeliveryCar/delete", //  车辆信息删除
  supplierDeliveryCarList: "/supplierDeliveryCar/list", //  车辆信息列表
  supplierDeliveryCarQuery: "/supplierDeliveryCar/queryDetail", //  车辆信息详情

  supplierDeliveryManAdd: "/supplierDeliveryMan/add", //  送货人新增
  supplierDeliveryManEdit: "/supplierDeliveryMan/audit", //  送货人修改
  supplierDeliveryManDelete: "/supplierDeliveryMan/delete", //  送货人删除
  supplierDeliveryManList: "/supplierDeliveryMan/list", //  送货人列表
  supplierDeliveryManQuery: "/supplierDeliveryMan/queryDetail", //  送货人详情

  selectShopInfoListByName: "/supplierDistrict/selectShopInfoListByName", // 经营信息>配送区域>查看商超
  selectSupplierDistrictList: "/supplierDistrict/selectSupplierDistrictList", // 经营信息>配送区域>列表

  /**
   * 用户信息
   */
  getSysUserList: "/sysUser/getSysUserList", // 列表
  getSysUserById: "/sysUser/getSysUserById", // 详情/{userId}
  addSysUser: "/sysUser/addSysUser", // 新增
  updateSysUser: "/sysUser/updateSysUser", // 修改
  deleteSysUser: "/sysUser/deleteSysUser", // 删除/{userId}

  /**
   * 基本信息
   */
  getInformation: "/supplierInfo/getInformation", // 查询个人信息
  updateInformation: "/supplierInfo/updateInformation", // 修改个人信息
  updatePassword: "/supplierInfo/updatePassword", // 修改密码

  /**
   * 部门/组织信息
   */
  getSysDepartmentList: "/sysDepartment/getSysDepartmentList", // 列表
  getSysDepartment: "/sysDepartment/getSysDepartment", // 详情/{departmentId}
  addSysDepartment: "/sysDepartment/addSysDepartment", // 新增
  updateSysDepartment: "/sysDepartment/updateSysDepartment", // 修改
  deleteSysDepartment: "/sysDepartment/deleteSysDepartment", // 删除/{departmentId}

  /**
   * 角色信息
   */
  getSysRoleList: "/sysRole/getSysRoleList", // 列表
  getSysRole: "/sysRole/getSysRole", // 详情/{roleId}
  addSysRole: "/sysRole/addSysRole", // 新增
  updateSysRole: "/sysRole/updateSysRole", // 修改
  deleteSysRole: "/sysRole/deleteSysRole", // 删除/{roleId}

  /**
   * 菜单信息
   */
  getSysMenuList: "/sysMenu/getSysMenuList", // 列表
  getSysMenu: "/sysMenu/getSysMenu", // 详情/{menuId}
  addSysMenu: "/sysMenu/addSysMenu", // 新增
  updateSysMenu: "/sysMenu/updateSysMenu", // 修改
  deleteSysMenu: "/sysMenu/deleteSysMenu", // 删除/{menuId}

  /**
   * 用户角色授权信息
   */
  getSysUserRoleList: "/sysUserRole/getSysUserRoleList", // 列表
  addSysUserRole: "/sysUserRole/addSysUserRole", // 新增
  deleteSysUserRole: "/sysUserRole/deleteSysUserRole", // 取消授权
}
