/**
 * 库存管理api
 */

export default {
  /**
   * 入库单管理
   */
  // 入库列表
  getInventoryInOrderList: '/inventoryInOrder/listPage',
  // 入库明细
  getInventoryInProduct: '/inventoryInProduct/listPage',
  // 导入
  importInventoryInProduct: '/inventoryInOrder/importInventoryInProduct',

  /**
   * 库存明细
   */
  // 库存明细列表
  getInventoryDetailsList: '/inventoryDetails/listPage',

  /**
   * 出库单管理
   */
  // 出库列表
  getInventoryOutOrderList: '/inventoryOutOrder/listPage',
  // 出库明细
  getInventoryOutProduct: '/inventoryOutProduct/listPage',

  /**
   * 库存统计
   */
  // 期末期初统计
  getBeginEndList: '/inventory_statistics/begin_end_list',
  // 出库统计
  getOutboundList: '/inventory_statistics/outbound_list',

  // 商超库存金额列表>查看(列表)
  shopListPage: '/shopProductInventoryStat/shopListPage',

  // 商超库存金额列表>查看(饼图){shopId}
  shopPieChart: '/shopProductInventoryStat/shopPieChart/',

  // 商超库存金额列表
  listPage: '/shopInventoryStat/listPage',

  // 商超库存金额饼图
  pieChart: '/shopInventoryStat/pieChart',
  // 商品库存金额列表
  productAmountList: '/productInventoryStat/listPage',
  // 商品库存金额饼图
  productAmountPieChart: '/productInventoryStat/pieChart',
  // 商品商超库存金额列表>查看(列表)
  productAmountViewList: '/productShopInventoryStat/supplierListPage',
  // 商品商超库存金额列表>查看(饼图){productId}
  productAmountViewPieChart: '/productShopInventoryStat/supplierPieChart',
};
