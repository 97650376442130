// 接单管理
export default [
  {
    path: '/technicalService',
    meta: { title: '技术服务费' },
    component: () => import('../views/technicalService/index.vue'),
    children: [
      {
        path: '/technicalService/expenseDet',
        meta: { title: '服务费明细', isMenu: true },
        name: 'expenseDet',
        component: () => import('../views/technicalService/expenseDet.vue'),
      },
      {
        path: '/technicalService/feePayment',
        meta: { title: '缴纳服务费', isMenu: true },
        name: 'feePayment',
        component: () => import('../views/technicalService/feePayment.vue'),
      },
      {
        path: '/technicalService/detail/twoCodePage',
        name: 'twoCodePage',
        meta: { title: '技术服务缴费页面' },
        component: () => import('../views/technicalService/detail/twoCodePage.vue'),
      },
      {
        path: '/technicalService/detail/feeDetails',
        meta: { title: '服务费详情' },
        name: 'feeDetails',
        component: () => import('../views/technicalService/detail/feeDetails.vue'),
      },
      
    ],
  },
]