/**
 * 商品管理api
 */

export default {
  /**
   * 供货商品
   */
  // 列表 (供货商品)(新)
  getSuppliedGoodsList2: "/biddingProduct/suppliedGoodsList2",
  // 列表 (供货商品)
  getSuppliedGoodsList: "/biddingProduct/suppliedGoodsList",
  // 商品上下架
  changeProductInfoSupplierAudit: "/biddingProduct/audit",
  // 编辑图片
  updateProductPic: "/biddingProduct/updateProductPic",
  getProductDetail: "/productInfoSupplier/productDetail", // 编辑-复现详情
  updateProduct: "/productInfoSupplier/updateProduct", // 编辑-确定
  exportExcel: "/biddingProduct/exportExcel", // 导出

  /**
   * 新品管理
   */
  // 列表 (新品管理)
  getProductInfoSupplierList: "/productInfoSupplier/applyList",
  // 商品添加（申请新商品）
  AddProductInfoSupplierList: "/productInfoSupplier/save",
  // 编辑 重新申请
  UpdateProductInfoSupplierList: "/productInfoSupplier/update",
  // 批量导入
  importNewProductApply: "/productInfoSupplier/importNewProductApply",
  // 详情
  getProductInfoSupplierDetail: "/productInfoSupplier/applyDetail",

  /**
   * 我的终端
   */
  // 终端列表
  getMyTerminal: "/productInfoSupplier/myTerminal",
  // 查看商超
  getMyTerminalShopInfo: "/productInfoSupplier/shopInfo",

  /**
   * 商超统计
   */
  // 商超统计列表
  getSupermarketStatistics: "/productInfoSupplier/supermarketStatistics",
  /**
   * 商品统计
   */
  // 商品统计列表
  getProductStatistics: "/productInfoSupplier/productStatistics",

  /**
   * 销售统计
   */
  //  商超销售统计-列表
  getSelectShopSaleList: "/data_statistics/select_shop_sale_list",
  //  商超销售统计-折线图图表
  getSelectShopSalePicChart: "/data_statistics/select_shop_sale_pic",
  //  商超销售统计查看详情-列表
  getShopSaleDetailList: "/data_statistics/select_shop_sale_product_list",
  //  商超销售统计查看详情-饼状图图表
  getShopSaleDetailPicChart: "/data_statistics/select_shop_sale_product_pic",

  //  商品销售统计-列表
  getSelectProductSaleList: "/data_statistics/select_product_sale_list",
  //  商品销售统计-图表
  getSelectProductSalePicChart: "/data_statistics/select_product_sale_pic",
  //  商品销售统计查看详情-列表
  getProductSaleDetailList: "/data_statistics/select_product_shop_sale_list",
  //  商品销售统计查看详情-饼图图表
  getProductSaleDetailPicChart: "/data_statistics/select_product_shop_sale_pic",

  /**
   * 有效期预警
   */
  // 列表
  getDeliveryProductList: "/deliveryProduct/list",
  // 取消预警（删除）
  deleteDeliveryProduct: "/deliveryProduct/delete",
  // 预警设置
  warningSettings: "/product_warn_set/warningSettings",
  // 默认设置
  defaultSettingSelectById: "/product_warn_set/selectById",

  /**
   * 平台分类
   */
  // 列表 ( 新品管理 平台分类)
  getCategoryInfoList: "/categoryInfo/list",
  /**
   * 我的分类
   */
  // 列表
  getMyCategoryInfoVo: "/categoryInfo/myCategoryInfoVo",

  // 增加分类两个输入框模式
  addTwoCategory: "/categoryInfo/addTwoCategory",
  // 撤销申请(删除)
  deleteCategory: "/categoryInfo/delete",
  // 分类详情
  queryCategoryInfoDetail: "/categoryInfo/query",
  // 两个输入框修改分类
  changeCategoryInfoPutTwo: "/categoryInfo/putTwo",
}
