// 调价管理
export default [
  {
    path: '/priceAdjus',
    meta: { title: '调价管理' },
    name: 'priceAdjus',
    component: () => import('../views/priceAdjus/index.vue'),
    // redirect: '/priceAdjus/list',
    children: [
      {
        path: '/priceAdjus/list',
        meta: { title: '调价列表', isMenu: true },
        name: 'list',
        component: () => import('../views/priceAdjus/list.vue'),
      },
      {
        path: '/priceAdjus/apply',
        meta: { title: '我的申请', isMenu: true },
        name: 'apply',
        component: () => import('../views/priceAdjus/apply.vue'),
      },
      {
        path: '/priceAdjus/detail/info',
        meta: { title: '调价信息', },
        name: 'info',
        component: () => import('../views/priceAdjus/detail/info.vue'),
      },
      {
        path: '/priceAdjus/detail/my',
        meta: { title: '申请详情', },
        name: 'my',
        component: () => import('../views/priceAdjus/detail/my.vue'),
      },
    ],
  },
]