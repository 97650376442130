// 在线交易管理
export default [
  {
    path: '/onlineTrading',
    meta: { title: '在线交易管理' },
    component: () => import('../views/onlineTrading/index.vue'),
    children: [
      {
        name: 'onlineTradingOrder',
        path: '/onlineTrading/order',
        meta: { title: '订单管理', isMenu: true },
        component: () => import('../views/onlineTrading/order.vue'),
      },
      {
        name: 'onlineTradingAfterSales',
        path: '/onlineTrading/afterSales',
        meta: { title: '售后管理', isMenu: true },
        component: () => import('../views/onlineTrading/afterSales.vue'),
      },
    ],
  },
]