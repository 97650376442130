/**   
 * 技术服务费api
 */
export default {
  // 技术服务费明细
techDetailInfo: "/tech_fee/tech_detail_info",
// 技术服务费列表
techInfoList: "/tech_fee/tech_info_list",
// 技术服务费详情
techFeeInfo: "/tech_fee/tech_info_info",

};