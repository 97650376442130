/**   
 * 结算管理api
 */

export default {
  /**
   * 销售明细
   */
  // 列表
  getSettlementInfoList: "/settlement_info/settlement_order_list",
  // 列表-查看详情
  getSettlementInfoDetail: "/settlement_info/settlement_order_info",

  /**
   * 商超统计
   */
  // 列表
  getSettlementShopList: "/settlement_info/settlement_shop_list",
  // 图表
  settlementShopSale: "/settlement_info/settlement_shop_sale",

  /**
   * 商品统计
   */
  // 列表
  getSettlementProductStatList: "/settlement_info/settlement_productStat_list",
  // 图表
  settlementProductSale: "/settlement_info/settlement_product_sale",

  /**
  * 提现申请
  */

  // 提现弹窗-下拉选择收款账户
  getExtractAccountDrop: "/extractAccount/drop",
  // 收款账号管理-列表
  getExtractAccountListPage: "/extractAccount/listPage",
  // 收款账号管理-新增
  getExtractAccountAdd: "/extractAccount/add",
  // 收款账号管理-编辑
  getExtractAccountUpdate: "/extractAccount/update",
  // 收款账号管理-删除
  getExtractAccountDelete: "/extractAccount/delete",

  // 列表顶部账户总览
  getSelectSettlementAccount: "/settlement_account/select_settlement_account",

  // 提现申请列表
  getSupplierExtractApplyList: "/supplierExtractApply/pageList",
  // 提现申请列表-查看详情
  getSupplierExtractApplyListDetail: "/supplierExtractApply/pageListDetails",
  // 提现申请
  postSupplierExtractApply: "/supplierExtractApply/supplierExtractApply",
  // 提现申请限制
  supplierExtractApplyToDo: "/supplierExtractApply/supplierExtractApplyToDo",
};