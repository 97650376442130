// 商品管理
export default [
  {
    path: '/products',
    meta: { title: '商品管理' },
    component: () => import('../views/products/index.vue'),
    // redirect: '/products/supply',
    children: [
      {
        path: '/products/supply',
        meta: { title: '供货商品', isMenu: true },
        component: () => import('../views/products/supply.vue'),
      },
      {
        path: '/products/new',
        meta: { title: '新品管理', isMenu: true },
        component: () => import('../views/products/new.vue'),
      },
      {
        path: '/products/terminal',
        meta: { title: '我的终端', isMenu: true },
        component: () => import('../views/products/terminal.vue'),
      },
      {
        path: '/products/detail/info',
        meta: { title: '查看商超信息', },
        component: () => import('../views/products/detail/info.vue'),
      },

      // 有二级
      {
        path: '/products/statistics',
        meta: { title: '数据统计', isMenu: true },
        // component: () => import('../views/products/statistics.vue'),
      },
      {
        path: '/products/statistics/saleShopStatistics',
        meta: { title: '商超销售统计', isMenu: true },
        component: () => import('../views/products/saleShopStatistics.vue'),
      },
      {
        path: '/products/statistics/saleProductStatistics',
        meta: { title: '商品销售统计', isMenu: true },
        component: () => import('../views/products/saleProductStatistics.vue'),
      },
      {
        path: '/products/statistics/supermarketStatistics',
        meta: { title: '商超统计', isMenu: true },
        component: () => import('../views/products/supermarketStatistics.vue'),
      },
      {
        path: '/products/statistics/productStatistics',
        meta: { title: '商品统计', isMenu: true },
        component: () => import('../views/products/productStatistics.vue'),
      },


      {
        path: '/products/validityWarning',
        meta: { title: '保质期预警', isMenu: true },
        component: () => import('../views/products/validityWarning.vue'),
      },
      {
        path: '/products/warningSetting',
        meta: { title: '预警设置', isMenu: true },
        component: () => import('../views/products/warningSetting.vue'),
      },
       {
        path: '/products/category',
        meta: { title: '分类列表', isMenu: true },
        component: () => import('../views/products/category.vue'),
      },
      {
        path: '/products/detail/apply',
        meta: { title: '申请新商品', },
        component: () => import('../views/products/detail/apply.vue'),
      },
       {
        path: '/products/detail/edit',
        meta: { title: '编辑', },
        component: () => import('../views/products/detail/edit.vue'),
      },
      {
        path: '/products/detail/saleShopStatisticsDetail',
        meta: { title: '商超销售统计详情', },
        component: () => import('../views/products/detail/saleShopStatisticsDetail.vue'),
      },
      {
        path: '/products/detail/saleProductStatisticsDetail',
        meta: { title: '商品销售统计详情', },
        component: () => import('../views/products/detail/saleProductStatisticsDetail.vue'),
      },
    ],
  },
]