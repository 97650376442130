// 接单管理
export default [
  {
    path: '/orderTaking',
    meta: { title: '接单管理' },
    component: () => import('../views/orderTakingManagement/index.vue'),
    children: [
      {
        path: '/orderTaking/purchaseOrder',
        meta: { title: '采购清单', isMenu: true },
        name: 'purchaseOrder',
        component: () => import('../views/orderTakingManagement/purchaseOrder.vue'),
      },
      {
        path: '/orderTaking/dispatchNote',
        meta: { title: '销售清单', isMenu: true },
        name: 'dispatchNote',
        component: () => import('../views/orderTakingManagement/dispatchNote.vue'),
      },
      {
        path: '/orderTaking/shippingList',
        meta: { title: '发货列表', isMenu: true },
        name: 'shippingList',
        component: () => import('../views/orderTakingManagement/shippingList.vue'),
      },
      {
        path: '/orderTaking/deliveryAbnormal',
        meta: { title: '收货异常', isMenu: true },
        name: 'deliveryAbnormal',
        component: () => import('../views/orderTakingManagement/deliveryAbnormal.vue'),
      },
      {
        path: '/orderTaking/detail/purchaseOrderDet',
        meta: { title: '采购单详情' },
        name: 'purchaseOrderDet',
        component: () => import('../views/orderTakingManagement/detail/purchaseOrderDet.vue'),
      },
      {
        path: '/orderTaking/detail/waitDeliveryDet',
        meta: { title: '发货详情' },
        name: 'waitDeliveryDet',
        component: () => import('../views/orderTakingManagement/detail/waitDeliveryDet.vue'),
      },
      {
        path: '/orderTaking/detail/completeDet',
        meta: { title: '查看详情' },
        name: 'completeDet',
        component: () => import('../views/orderTakingManagement/detail/completeDet.vue'),
      },
      {
        path: '/orderTaking/detail/completeAllDet',
        meta: { title: '查看发货详情' },
        name: 'completeAllDet',
        component: () => import('../views/orderTakingManagement/detail/completeAllDet.vue'),
      },
    ],
  },
]