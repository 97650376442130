// 退换货管理
export default [
  {
    path: '/refundsExchanges',
    meta: { title: '退换货管理' },
    component: () => import('../views/refundsExchanges/index.vue'),
    // redirect: '/refundsExchanges/refundsList',
    children: [
      {
        path: '/refundsExchanges/refundsList',
        name: 'refundsList',
        meta: { title: '退换列表', isMenu: true },
        component: () => import('../views/refundsExchanges/refundsList.vue'),
      },
      {
        path: '/refundsExchanges/detail/info',
        name: 'refundsDetail',
        meta: { title: '退换货信息' },
        component: () => import('../views/refundsExchanges/detail/info.vue'),
      },
    ],
  },
]