/**
 * 接单管理api
 */
export default {
  /**
   * (原采购单)采购清单
   */
  // 采购单列表
  sendOrderList: "/purchase_order/list",
  // 采购单详情
  sendOrderInfo: "/purchase_order/info",
  // 采购单接单
  sendOrderConfirmOrder: "/purchase_order/confirm_order",
  // 采购单发货
  sendOrderConfirmShipment: "/purchase_order/confirm_shipment",
  /**
   * (原发货单)销售清单
   */
  // 发货单列表
  sendDeliveryList: "/delivery_order/list",
  // 发货单详情
  sendDeliveryInfo: "/delivery_order/info",
  // 送货人信息列表
  sendDeliveryManList: "/supplierDeliveryMan/list",
  // 送货车辆信息列表
  sendDeliveryCarList: "/supplierDeliveryCar/list",
  // 上传发货单图片中转站
  sendUploadFile: "/attachment/uploadFile",
  // 上传发货单图片
  sendUpload: "/delivery_order/update_url_by_id",
  /**
   * 发货信息
   */
  // 发货信息列表
  sendDeliveryProductList: "/delivery_order/delivery_product_list",
  // 发货异常处理
  sendExceptions: "/delivery_order/exceptions",
  delivery_product_list_num: "/delivery_order/delivery_product_list_num", // 发货列表数量
  addRemarks: "/delivery_order/add_remarks", // 发货列表添加备注
}
