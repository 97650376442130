/**
 * 接口统一管理
 */
import common from "./common.js";
import system from "./system.js";
import product from "./product.js";
import bid from "./bidManagement.js";
import customerService from "./customerService.js";
import priceAdjus from "./priceAdjus.js";
import settlement from "./settlement.js";
import order from "./orderTakManagement.js";
import refunds from "./refunds.js";
import stock from "./stock.js";
import contract from "./conManagement";
import technicalService from "./technicalService";

const apiAll = [
    system,
    common,
    product,
    bid,
    customerService,
    priceAdjus,
    settlement,
    order,
    refunds,
    stock,
    contract,
    technicalService,
]

// 判断接口是否已存在
function isKeyQepeat(arr) {
    let keyArr = [], apiObj = {};
    arr.forEach((item) => {
        keyArr.push(...Object.keys(item))
        Object.assign(apiObj, item)
    })
    try {
        keyArr.forEach((item, i, arr) => {
            if (arr.indexOf(item) !== i) {
                throw new Error(`接口 key 重复项: ${item}`)
            }
        })
    } catch (error) {
        console.error('[错误]:', error);
        return null
    }
    return apiObj
}
const apiDesc = isKeyQepeat(apiAll)
//进行统一导出 
export default {
    ...apiDesc
}