/**
 * 公共api
 */
export default {
  //登录
  login: "/userLogin/login",
  //供应商注册
  register: "/supplierInfo/registerSupplier",
  //查询供应商手机号是否可用/{supplierPhone}
  selectSupplierPhone: "/supplierInfo/selectSupplierPhone",
  //获取短信验证码
  SMSCode: "/userLogin/SMSCode",
  //提交短信验证码
  CheckSMSCode: "/userLogin/CheckSMSCode",
  //修改密码
  retrievePassword: "/supplierInfo/retrievePassword",
  //查询供应商是否存在
  selectSupplierName: "/supplierInfo/selectSupplierName",
  //查询申请
  selectApply: "/supplierInfo/selectApply",
  //文件上传，返回地址
  uploadFile: "/attachment/uploadFile",
  //营业执照文字识别
  getBizLicenseInfoOCR: "/sysOCR/getBizLicenseInfo",
  // 查询省市区
  getDistrictInfo: "/districtInfo/getDistrictInfo",
  // 查询省市
  getSecondDistrictInfo: "/districtInfo/getSecondDistrictInfo",

  /**
   * 第三方聚合支付api
   * dev：https://dev-longlon.eshare168.com
   * per：https://api.eshare168.com
   */
  // 获取授权code
  thirdOauthCode: "https://api.eshare168.com/r/sso/oauth/code",
  // 通过临时token调用API短信接口
  thirdCaptchaSms: "https://api.eshare168.com/r/sso/oauth/captchaSms",
  // 获取授权token
  thirdOauthLogin: "https://api.eshare168.com/r/sso/oauth/login",

  // 用第三方code换取临时token
  payGetToken: "/pay/get_token",
  // 查询用户信息
  payGetInfo: "/pay/get_info",
  // 绑定用户
  payBind: "/pay/bind",
  // 支付
  // payInfo: "/pay/pay_info",
  payInfo: "/weChat/WeChat_QR_code_pay",
  // 检查用户是否绑定token
  select_token: "/pay/select_token",
  // 检查支付状态
  // select_pay: "/pay/select_pay",
  select_pay: "/weChat/select_pay_type",

  // pcLogin:'/tencentIm/pcLogin', // pc登录im
  loginIM: "/tencentIm/login", // 登录腾讯im
}
