// 结算管理
export default [
  {
    path: '/settlement',
    meta: { title: '结算管理' },
    component: () => import('../views/settlement/index.vue'),
    // redirect: '/settlement/saleDetail',
    children: [
      {
        path: '/settlement/saleDetail',
        meta: { title: '结算明细', isMenu: true },
        component: () => import('../views/settlement/saleDetail.vue'),
      },
      {
        path: '/settlement/detail/settlementDetail',
        meta: { title: '结算明细详情', },
        component: () => import('../views/settlement/detail/settlementDetail.vue'),
      },
      {
        path: '/settlement/withdrawalApplication',
        meta: { title: '提现申请', isMenu: true },
        component: () => import('../views/settlement/withdrawalApplication.vue'),
      },
      {
        path: '/settlement/detail/accountManagement',
        meta: { title: '收款账号管理', },
        component: () => import('../views/settlement/detail/accountManagement.vue'),
      },
      {
        path: '/settlement/detail/applyDetail',
        meta: { title: '提现申请详情', },
        component: () => import('../views/settlement/detail/applyDetail.vue'),
      },
      {
        path: '/settlement/statistics',
        meta: { title: '结算统计', isMenu: true },
      },
      {
        path: '/settlement/statistics/supermarketStatistics',
        meta: { title: '商超统计', isMenu: true },
        component: () => import('../views/settlement/supermarketStatistics.vue'),
      },
      {
        path: '/settlement/statistics/productStatistics',
        meta: { title: '商品统计', isMenu: true },
        component: () => import('../views/settlement/productStatistics.vue'),
      },
    ],
  },
]