// 库存管理
export default [
  {
    path: '/stock',
    meta: { title: '库存管理' },
    component: () => import('../views/stock/index.vue'),
    // redirect: '/stock/enterManagement',
    children: [
      {
        path: '/stock/enterManagement',
        redirect: '/stock/enterManagement/enterList',
        meta: { title: '入库单管理', isMenu: true },
      },
      {
        path: '/stock/enterManagement/enterList',
        meta: { title: '入库列表', isMenu: true },
        component: () => import('../views/stock/enterList.vue'),
      },
      {
        path: '/stock/enterManagement/enterDetail',
        meta: { title: '入库明细', isMenu: true },
        component: () => import('../views/stock/enterDetail.vue'),
      },

      {
        path: '/stock/leaveManagement',
        meta: { title: '出库单管理', isMenu: true },
      },
      {
        path: '/stock/leaveManagement/leaveList',
        meta: { title: '出库列表', isMenu: true },
        component: () => import('../views/stock/leaveList.vue'),
      },
      {
        path: '/stock/leaveManagement/leaveDetail',
        meta: { title: '出库明细', isMenu: true },
        component: () => import('../views/stock/leaveDetail.vue'),
      },
      {
        path: '/stock/inventoryList',
        meta: { title: '库存明细', isMenu: true },
        component: () => import('../views/stock/inventoryList.vue'),
      },
      {
        path: '/stock/stockStatistics',
        meta: { title: '库存统计', isMenu: true },
      },
      {
        path: '/stock/stockStatistics/semester',
        meta: { title: '期初期末统计', isMenu: true },
        component: () => import('../views/stock/semester.vue'),
      },
      {
        path: '/stock/stockStatistics/outbound',
        meta: { title: '出库统计', isMenu: true },
        component: () => import('../views/stock/outbound.vue'),
      },
      {
        path: '/stock/stockStatistics/productAmount',
        meta: { title: '商品库存金额', isMenu: true },
        component: () => import('../views/stock/productAmount.vue'),
      },
      {
        path: '/stock/stockStatistics/supermarketAmount',
        meta: { title: '商超库存金额', isMenu: true },
        component: () => import('../views/stock/supermarketAmount.vue'),
      },
      {
        path: '/stock/detail/supermarketDetail',
        meta: { title: '商品库存金额详情', },
        component: () => import('../views/stock/detail/supermarketDetail.vue'),
      },
      {
        path: '/stock/detail/leaveListDetail',
        meta: { title: '出库单详情', },
        component: () => import('../views/stock/detail/leaveListDetail.vue'),
      },
      {
        path: '/stock/detail/productAmountDet',
        meta: { title: '商品库存金额详情', },
        component: () => import('../views/stock/detail/productAmountDet.vue'),
      },
      {
        path: '/stock/detail/enterListDetail',
        meta: { title: '入库单详情', },
        component: () => import('../views/stock/detail/enterListDetail.vue'),
      },
    ],
  },
]