/**
 * 合同管理api
 */
export default {
  /**
   *  合同列表
   */
  // 合同列表
  contractsListBySupplier: "/contractInfo/contractsListBySupplier",
  // 供应商合同签署
  sendContractSupplierSign: "/contractInfo/supplierSign/",
  // 供应商合同预览
  sendContractViewContract: "/contractInfo/viewContract",

  createSignature: "/signatureInfo/createSignature", // 创建签章
  deleteSignatureInfo: "/signatureInfo/deleteSignatureInfo", // 删除签章
  insertSignatureInfo: "/signatureInfo/insertSignatureInfo", // 新增签章
  selectSignatureInfoDetail: "/signatureInfo/selectSignatureInfoDetail", // 签章详情
  selectSignatureInfoList: "/signatureInfo/selectSignatureInfoList", // 签章列表
  selectSignatureTypeList: "/signatureInfo/selectSignatureTypeList", // 查询签章类型
  updateSignatureInfo: "/signatureInfo/updateSignatureInfo", // 修改签章

  signTendersContract: "/contractInfo/signTendersContract", // 签署投标合同
  contractTendersList: "/contractInfo/contractTendersList", // 标书-合同列表
}
